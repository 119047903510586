"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
(function () {
  function justReturnsFirstArgument(x) {
    return x;
  }
  function dependencies(window) {
    return {
      GAEClient: window.GAEClient,
      models: window.moduleResolver(window)
    };
  }
  function getResultMessageData(result) {
    if (result && result.message && result.message.data) {
      return result.message.data;
    }
    return result && result.message;
  }
  function createGAERequest(_ref, _ref2) {
    var GAEClient = _ref.GAEClient,
      requestStrategy = _ref.requestStrategy;
    var method = _ref2.method,
      url = _ref2.url,
      afterSendParser = _ref2.afterSendParser;
    return function executeGAERequest(modelInstance) {
      var data = typeof modelInstance.data === 'function' ? modelInstance.data() : modelInstance;
      var httpRequester = GAEClient(requestStrategy)[method];
      return httpRequester(url, data).then(getResultMessageData).then(afterSendParser)["catch"](function (GAEClientError) {
        var message = {},
          response = {};
        if ('message' in GAEClientError) {
          try {
            message = JSON.parse(GAEClientError.message);
          } catch (err) {
            console.error(err);
            message = GAEClientError.message;
          }
        }
        if (_typeof(message) === 'object' && 'data' in message) {
          response = afterSendParser(message.data);
          return Promise.reject(response);
        }
        console.warn(GAEClientError.stack);
        return Promise.reject(message);
      });
    };
  }
  function Repository(_ref3, resolvedDependencies) {
    var url = _ref3.url,
      afterSendParser = _ref3.afterSendParser,
      errorTreatment = _ref3.errorTreatment;
    return {
      insert: createGAERequest(resolvedDependencies, {
        method: 'put',
        url: url,
        afterSendParser: afterSendParser,
        errorTreatment: errorTreatment
      }),
      update: createGAERequest(resolvedDependencies, {
        method: 'post',
        url: url,
        afterSendParser: afterSendParser,
        errorTreatment: errorTreatment
      }),
      "delete": createGAERequest(resolvedDependencies, {
        method: 'delete',
        url: url,
        afterSendParser: afterSendParser,
        errorTreatment: errorTreatment
      }),
      findAll: createGAERequest(resolvedDependencies, {
        method: 'get',
        url: url,
        afterSendParser: afterSendParser,
        errorTreatment: errorTreatment
      })
    };
  }
  var repositories = new Map();
  var RepositoryFactory = {
    "for": function _for(_ref4, injection) {
      var model = _ref4.model,
        requestStrategy = _ref4.requestStrategy;
      var repository = repositories.get(model);
      if (repository) return repository;
      var resolvedDependencies = Object.assign({}, dependencies(window), injection);
      var models = resolvedDependencies.models;

      // const {url, afterSendParser, errorTreatment} = models.get(model);
      var newRepository = Repository(models.get(model), Object.assign({}, {
        requestStrategy: requestStrategy
      }, resolvedDependencies));
      repositories.set(model, newRepository);
      return newRepository;
    },
    justReturnsFirstArgument: justReturnsFirstArgument
  };
  window.moduleExport({
    RepositoryFactory: RepositoryFactory
  }, typeof module !== 'undefined' && module);
})();